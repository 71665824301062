import { Component, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core'

export interface PageImageEvent {
  file: File
}

@Component({
  selector: 'page-image',
  styleUrls: ['page-image.component.scss'],
  templateUrl: 'page-image.component.html'
})
export class PageImageComponent {
  @Input()
  public width: string

  @Input()
  public height: string

  @Input()
  public loading: boolean

  @Input()
  public url: any

  @Output()
  public imageChange: EventEmitter<PageImageEvent>

  @ViewChild('inputFile', {static: false})
  public inputFile: ElementRef

  constructor() {
    this.imageChange = new EventEmitter()
  }

  public selectFile() {
    this.inputFile.nativeElement.click()
  }

  public onFileSelect(event) {
    let reader = new FileReader()
    let image = new Image()
    const target = event.srcElement || event.currentTarget

    let file: File = target['files'][0]

    image.onload = () => {
      reader.onload = (e) => {
        let data = e.target['result']

        this.url = data
        this.imageChange.emit({file})
      }

      reader.readAsDataURL(file)
    }

    image.src = URL.createObjectURL(file)
  }
}
