import { Component, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core'
import { BaseError } from '@api/errors'



export interface InputImageBackgroundEvent {
  file: File
}

export interface InputBoxSize {
  w: number,
  h: number
}

export interface Position {
  l: number,
  r: number,
  t: number,
  b: number
}

@Component({
  selector: 'input-image-background',
  styleUrls: ['input-image-background.component.scss'],
  templateUrl: 'input-image-background.component.html'
})
export class InputImageBackgroundComponent {
  @Input()
  public width: number

  @Input()
  public height: number

  @Input()
  public uploading: boolean

  /// rectangle simi-round round
  @Input()
  public buttonShape:string

  @Input()
  public shapeColor:string

  @Input()
  public buttonLabel: string

  @Input()
  public boxSize : InputBoxSize

  @Input()
  public borderRadius : number

  @Input()
  public buttonPosition: Position

  @Input()
  public size: {w, h}

  @Input()
  public placeholderWidth: number

  @Input()
  public placeholderHeight: number

  @Input()
  public fontSize: number

  @Input()
  public url: string

  @Input()
  public src: string

  @Input()
  public useDefaultSrc: boolean = false

  @Input()
  public pointer: boolean = true

  @Output()
  public imageChange: EventEmitter<InputImageBackgroundEvent>

  @Output()
  public error: EventEmitter<BaseError>

  @ViewChild('inputFile', {static: true})
  public inputFile: ElementRef

  public imageUrl: any

  constructor() {
    this.error = new EventEmitter()
    this.imageChange = new EventEmitter()
  }

  public selectFile() {
    this.inputFile.nativeElement.click()
  }

  public onFileSelect(event) {
    let reader = new FileReader()
    let image = new Image()
    const target = event.srcElement || event.currentTarget
    let file: File = target['files'][0]

    image.onload = () => {
      if (this.limitSize) {
        let widthDiff = Math.abs( image.width - this.actualWidth )
        let heightDiff = Math.abs( image.height - this.actualHeight )

        if (widthDiff > 10 || heightDiff > 10) {
          this.error.emit(new BaseError(0, 'Incorrect image size'))
          return
        }
      }

      reader.onload = (e) => {
        let data = e.target['result']

        this.imageUrl = data
        this.imageChange.emit({file})
      }

      reader.readAsDataURL(file)
    }

    image.src = URL.createObjectURL(file)
  }

  public get actualWidth(): number {
    return this.size ? this.size.w : this.width
  }

  public get actualHeight(): number {
    return this.size ? this.size.h : this.height
  }

  public get boxWidth() {
    return this.boxSize ? this.boxSize.w ? this.boxSize.w + 'px' : this.boxSize.h + 'px' ? this.boxSize.h : '100%' : '100%'
  }

  public get boxHeight() {
    return this.boxSize ? this.boxSize.h ? this.boxSize.h + 'px': this.boxSize.w + 'px' ? this.boxSize.w : '100%' : '100%'
  }

  public get left() {
    return this.buttonPosition ? this.buttonPosition.l ? this.buttonPosition.l + 'px' : '0px' : '0px'
  }

  public get button() {
    return this.buttonPosition ? this.buttonPosition.b ? this.buttonPosition.b + 'px' : '0px' : '0px'
  }

  public get right() {
    return this.buttonPosition ? this.buttonPosition.r ? this.buttonPosition.r + 'px' : '0px' : '0px'
  }

  public get top() {
    return this.buttonPosition ? this.buttonPosition.t ? this.buttonPosition.t + 'px' : '0px' : '0px'
  }

  public get borderBackground() {
    return this.borderRadius ? this.borderRadius + '%': 0 + '%'
  }

  public get ShapeofButton() {
    return this.buttonShape ? this.buttonShape : 'rectangle'
  }

  public get color() {
    return this.shapeColor ? this.shapeColor : 'lightgray'
  }

  public get limitSize() {
    return this.actualHeight != undefined && this.actualWidth != undefined
  }
}
