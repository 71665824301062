import { FormsModule } from '@angular/forms'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { NbButtonModule, NbIconModule, NbSpinnerModule } from '@nebular/theme'

import { InputImageSelectComponent } from './input-image-select.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NbButtonModule,
    NbIconModule,
    NbSpinnerModule
  ],
  exports: [
    InputImageSelectComponent
  ],
  declarations: [
    InputImageSelectComponent
  ]
})
export class InputImageSelectModule {

}
