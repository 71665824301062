import { FormsModule } from '@angular/forms'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { NbButtonModule, NbIconModule, NbSpinnerModule } from '@nebular/theme'

import { PageImageComponent } from './page-image.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NbButtonModule,
    NbIconModule,
    NbSpinnerModule
  ],
  exports: [
    PageImageComponent
  ],
  declarations: [
    PageImageComponent
  ]
})
export class PageImageModule {

}
