import { ActiveStatus } from "../../@api/dto/driver.dto";

export const ACTIVE_STATUS = {
  [ActiveStatus.TRUE]: {
    fontColor: 'white',
    'backgroundColor': '#009900',
    'width': '110px',
    'height': '25px'
  },
  [ActiveStatus.FALSE]: {
    fontColor: 'white',
    'backgroundColor': '#e02020',
    'width': '110px',
    'height': '25px'
  }
}