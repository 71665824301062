import { FormsModule } from '@angular/forms'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { NbButtonModule, NbIconModule, NbSpinnerModule } from '@nebular/theme'

import { InputImageBackgroundComponent } from './input-image-background.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NbButtonModule,
    NbIconModule,
    NbSpinnerModule
  ],
  exports: [
    InputImageBackgroundComponent
  ],
  declarations: [
    InputImageBackgroundComponent
  ]
})
export class InputImageBackgroundModule {

}
